import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { QueryData } from '../searchSections/types'

export enum ItemType {
  GENERAL = 'GENERAL',
  DETAIL = 'DETAIL'
}

export type Item = {
  searchId: string | null
  executionId: string | null
  description: string
  price: number
  customId?: string
  warning?: string
}

export type PaymentData = {
  email: string
  type: ItemType
  params: { causeId: string; executionId: string }[] | Omit<QueryData, 'exIds'>
  paymentType: Option
  gateType: GatewayType
  platform: PlatformType
  env?: 'localhost' | ''
}

export enum PlatformType {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE'
}

export enum Option {
  APPLE = 'APPLE_PAY',
  GOOGLE = 'GPAY',
  BANK = 'BANK_ACCOUNT',
  CARD = 'PAYMENT_CARD',
  SMS = 'MPAYMENT',
  DELAYED_PAYMENT = 'DELAYED_PAYMENT',
  DELAYED_PAYMENT_COMGATE = 'LATER_ALL',
  COMGATE_CARD = 'CARD_ALL',
  COMGATE_BANK = 'BANK_ALL',
  COMGATE_ALL = 'ALL'
}

export enum GatewayType {
  COMGATE = 'COMGATE',
  GOPAY = 'GOPAY'
}

export type SummaryRow = { name: string; value: string; icon: IconDefinition; tooltip?: string }
